import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getAccountBalancesActionsAction, getRwaContractBalancesAction, getTreasuryTransactionsAction, getTreasuryTransactionsMetaAction } from "./treasury.effects";

export const selectTreasuryState = (state: RootState) => state.treasury;

export const selectAccountBalances = createSelector(
    selectTreasuryState,
    state => state.accountBalances || []
)

export const selectIsGetAccountBalancesPending = createSelector(
    selectTreasuryState,
    state => state[getAccountBalancesActionsAction.pending.type]
)

export const selectRwaContractBalances = createSelector(
    selectTreasuryState,
    state => state.rwaContractBalances
)

export const selectIsGetRwaContractBalancesPending = createSelector(
    selectTreasuryState,
    state => state[getRwaContractBalancesAction.pending.type]
)

export const selectTreasuryTransactionsMeta = createSelector(
    selectTreasuryState,
    state => state.transactionsMeta || []
)

export const selectIsGetTreasuryTransactionsMetaPending = createSelector(
    selectTreasuryState,
    state => state[getTreasuryTransactionsMetaAction.pending.type]
)

export const selectTreasuryTransactions = createSelector(
    selectTreasuryState,
    state => state.transactions
)

export const selectIsGetTreasuryTransactionsPending = createSelector(
    selectTreasuryState,
    state => state[getTreasuryTransactionsAction.pending.type]
)