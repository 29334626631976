import { Close, Edit, MoreVert, Tab } from "@mui/icons-material";
import { Button, Divider, IconButton } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import LoadingOverlay from "../../../components/LoadingOverlay/LoadingOverlay";
import MoreMenu from "../../../components/MenuMore/MoreMenu";
import Tabs from "../../../components/Tabs/Tabs";
import {
  selectIsGetTreasuryTransactionsMetaPending,
  selectIsGetTreasuryTransactionsPending,
  selectTreasuryTransactionsMeta,
} from "../treasury.selectors";
import { TreasuryTransactionType } from "../types/treasury-transaction-type.type";
import { TreasuryTransaction } from "../types/treasury-transaction.type";
import TreasuryInternalTransactions from "./TreasuryInternalTransactions";

export default function TreasuryTransactions() {
  const transactionsMeta = useSelector(selectTreasuryTransactionsMeta);
  const isGetTransactionsMetaPending = useSelector(
    selectIsGetTreasuryTransactionsMetaPending
  );
  const isGetTransactionsPending = useSelector(
    selectIsGetTreasuryTransactionsPending
  );
  const [selectedTab, setSelectedTab] = useState(0);
  const selectedTransactionMeta = transactionsMeta?.[selectedTab];
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );

  const renderTabContent = (meta: TreasuryTransaction) => {
    switch (meta.type) {
      case TreasuryTransactionType.Internal:
        return <TreasuryInternalTransactions id={meta.id} />;
    }
  };

  const showMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setMenuAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  return (
    <LoadingOverlay
      loading={isGetTransactionsMetaPending || isGetTransactionsPending}
    >
      <div className="w-full h-full flex flex-col">
        <div className="flex items-center justify-between">
          <Tabs
            selectedTab={selectedTab}
            items={transactionsMeta}
            renderItem={(item) => (
              <div className="flex items-center gap-2">
                {item.icon && <img width={20} src={item.icon} />}
                {item.title}
              </div>
            )}
            onTabChange={setSelectedTab}
          />

          <IconButton className="mt-1 mr-4" size="small" onClick={showMenu}>
            <MoreVert />
          </IconButton>
        </div>
        <div className="flex-1">
          {selectedTransactionMeta && renderTabContent(selectedTransactionMeta)}
        </div>
      </div>

      <MoreMenu anchorEl={menuAnchorEl} onClose={closeMenu}>
        <div className="flex flex-col px-1">
          <Button
            className="justify-start"
            variant="text"
            color="info"
            startIcon={<Tab />}
          >
            Add New Tab
          </Button>
          <Divider />
          <Button
            className="justify-start"
            variant="text"
            color="primary"
            startIcon={<Edit />}
          >
            Edit Tab
          </Button>
          <Divider />
          <Button
            className="justify-start"
            variant="text"
            color="error"
            startIcon={<Close />}
          >
            Remove Tab
          </Button>
        </div>
      </MoreMenu>
    </LoadingOverlay>
  );
}
