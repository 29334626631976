import { Close } from "@mui/icons-material";
import { Breakpoint, Dialog, IconButton } from "@mui/material";
import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";

export type CloseModal<T = unknown> = (result?: T) => void;

interface IProps {
  openModal: (
    component: (props: { close: CloseModal }) => React.ReactNode,
    opts?: {
      closeOnClickOutside?: boolean;
      hideCloseButton?: boolean;
      maxWidth?: Breakpoint;
    }
  ) => Promise<any>;
}

export const ModalContext = createContext<IProps>({
  openModal: () => Promise.resolve(null),
});

const OPTS_DEFAULT: Parameters<IProps["openModal"]>[1] = {
  closeOnClickOutside: true,
  hideCloseButton: true,
  maxWidth: "sm",
};

export default function ModalProvider({ children }) {
  const resolveRef = useRef<((any) => void) | null>(() => {});
  const [Component, setComponent] = useState<(props) => React.ReactNode>();
  const [opts, setOpts] =
    useState<Parameters<IProps["openModal"]>[1]>(OPTS_DEFAULT);
  const contentRef = useRef<any>();

  const open: IProps["openModal"] = useCallback(
    (component, opts): Promise<any> => {
      return new Promise((resolve) => {
        resolveRef.current = resolve;
        setComponent(() => component);
        if (opts) setOpts(opts);
      });
    },
    []
  );

  const close = useCallback(
    (result?: any) => {
      setComponent(undefined);
      setOpts(OPTS_DEFAULT);
      resolveRef.current?.(result);
    },
    [resolveRef.current]
  );

  const handleClickOutside = useCallback(() => {
    if (opts?.closeOnClickOutside) {
      close();
    }
  }, [opts]);

  return (
    <ModalContext.Provider value={{ openModal: open }}>
      <>
        {children}
        <Dialog
          maxWidth={opts?.maxWidth || "sm"}
          open={!!Component}
          onClose={handleClickOutside}
        >
          <div className="relative" ref={contentRef}>
            {Component?.({ close })}
            {!opts?.hideCloseButton && (
              <IconButton
                tabIndex={-1}
                onClick={() => close()}
                className="absolute top-3 right-3"
              >
                <Close />
              </IconButton>
            )}
          </div>
        </Dialog>
      </>
    </ModalContext.Provider>
  );
}

export const useModal = () => useContext(ModalContext);
