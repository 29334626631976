import { createSlice } from "@reduxjs/toolkit";
import { CommodityTokenTx, Paginated, TreasuryAccountBalance } from "../../types";
import { addCommonReducers } from "../../utils/store-utils";
import { getAccountBalancesActionsAction, getRwaContractBalancesAction, getTreasuryTransactionsAction, getTreasuryTransactionsMetaAction } from "./treasury.effects";
import { RwaContractBalances } from "./types/rwa-contract-balances.type";
import { TreasuryTransaction } from "./types/treasury-transaction.type";

export const TREASURY_STATE_NAME = 'treasury';

export interface TreasuryState {
    accountBalances: TreasuryAccountBalance[],
    rwaContractBalances: RwaContractBalances
    transactionsMeta: TreasuryTransaction[]
    transactions: { id: TreasuryTransaction['id'], payload: Paginated<CommodityTokenTx> | string }
}

export const treasuryState = createSlice({
    name: TREASURY_STATE_NAME,
    initialState: {
        accountBalances: []
    } as TreasuryState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAccountBalancesActionsAction.fulfilled, (state, action) => {
                state.accountBalances = action.payload;
            })
            .addCase(getRwaContractBalancesAction.fulfilled, (state, action) => {
                state.rwaContractBalances = action.payload;
            })
            .addCase(getTreasuryTransactionsMetaAction.fulfilled, (state, action) => {
                state.transactionsMeta = action.payload;
            })
            .addCase(getTreasuryTransactionsAction.fulfilled, (state, action) => {
                state.transactions = action.payload;
            });
        addCommonReducers(builder, TREASURY_STATE_NAME);
    }
});

export const treasuryActions = treasuryState.actions;
export const treasuryReducer = treasuryState.reducer;