import { createSelector } from "@reduxjs/toolkit";
import { Category, Erc20TokenInfo, SubCategory } from "../../types";
import { RootState } from "../store";
import { getConfigsAction } from "./settings.effects";

export const selectSettingsState = (state: RootState) => state.settings;

export const selectConfigs = createSelector(
  selectSettingsState,
  (state) => state.configs
);

export const selectIsGetConfigsPending = createSelector(
  selectSettingsState,
  (state) => state[getConfigsAction.pending.type]
);

export const selectRegions = createSelector(
  selectConfigs,
  (configs) => configs.regions || []
);

export const selectCountries = createSelector(
  selectConfigs,
  (configs) => configs?.countries || []
);

export const selectCategories = createSelector(
  selectConfigs,
  (configs) => configs?.categories || []
);

export const selectSubCategoriesWithHsCodes = createSelector(
  selectCategories,
  (categories) =>
    categories.reduce(
      (subs, cat) => [...subs, ...cat.subCategories],
      [] as SubCategory[]
    )
);

export const selectSubCategories = createSelector(
  selectSubCategoriesWithHsCodes,
  (subCategories) =>
    subCategories.map((sub) => ({ id: sub.id, name: sub.name }))
);

export const selectSubCategoryById = createSelector(
  [selectCategories, (_, subCategoryId) => subCategoryId],
  (categories: Category[], subCategoryId: SubCategory["id"]) => {
    let subCategory: SubCategory;
    const category = categories?.find((c) => {
      subCategory = c.subCategories?.find((sc) => sc.id === subCategoryId);
      return !!subCategory;
    });
    return category
      ? ({
        ...subCategory,
        category: { id: category.id, name: category.name },
      } as SubCategory)
      : null;
  }
);

export const selectBlockchainConfigs = createSelector(
  selectConfigs,
  (configs) => configs?.blockchain
);

export const selectBlockchainNativeToken = createSelector(
  selectBlockchainConfigs,
  (configs) => configs?.nativeToken
);

export const selectBlockchainPaymentToken = createSelector(
  selectBlockchainConfigs,
  configs => configs?.paymentToken
)

export const selectBlockchainFeeToken = createSelector(
  selectBlockchainConfigs,
  configs => configs?.feeToken
)

export const selectDealContractAddress = createSelector(
  selectBlockchainConfigs,
  configs => configs?.dealContractAddress
)

export const selectCommodityExchangeTokens = createSelector(
  selectBlockchainConfigs,
  (configs) =>
    Object.values(configs?.commodityExchangeTokens || {}) as Erc20TokenInfo[]
);

export const selectCommodityTokenConversionCommissionToken = createSelector(
  selectBlockchainConfigs,
  (configs) => configs?.conversionCommissionToken
);

export const selectCommodityTokenMarketplaceContractAddress = createSelector(
  selectBlockchainConfigs,
  configs => configs?.commodityTokenMarketplaceContractAddress
)