import api from ".";
import { AccountBalanceNotifier } from "../modules/treasury/types/account-balance-notifier.entity";
import { RwaContractBalances } from "../modules/treasury/types/rwa-contract-balances.type";
import { SaveAccountBalanceNotifierRequest } from "../modules/treasury/types/save-account-balance-notifier-request.type";
import { TreasuryTransaction } from "../modules/treasury/types/treasury-transaction.type";
import { UpdateRwaContractBalanceRequest } from "../modules/treasury/types/update-rwa-balance-request.type";
import { CommodityTokenTx, Paginated, TreasuryAccountBalance } from "../types";

export const treasuryApi = {
    async getAccountBalances(): Promise<TreasuryAccountBalance[]> {
        return (await api.get('/treasury-management/account-balances')).data;
    },
    async saveAccountBalance(dto: SaveAccountBalanceNotifierRequest): Promise<AccountBalanceNotifier> {
        return (await api.post('/treasury-management/account-balances', dto)).data;
    },
    deleteAccountBalance(id: TreasuryAccountBalance['id']): Promise<void> {
        return api.delete(`/treasury-management/account-balances/${id}`);
    },
    async getRwaContractBalances(): Promise<RwaContractBalances> {
        return (await api.get('/treasury-management/rwa-contract-balances')).data;
    },
    updateRwaContractBalance(dto: UpdateRwaContractBalanceRequest): Promise<void> {
        return api.put('/treasury-management/rwa-contract-balances', dto);
    },
    async getTreasuryTransactionsMeta(): Promise<TreasuryTransaction[]> {
        return (await api.get('/treasury-management/txs/meta')).data;
    },
    async getTreasuryTxs(id: TreasuryTransaction['id'], params): Promise<{
        id: TreasuryTransaction['id'];
        payload: Paginated<CommodityTokenTx> | string;
    }> {
        return (await api.get(`/treasury-management/txs/${id}`, { params })).data;
    }
}