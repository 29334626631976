import { createAsyncThunk } from "@reduxjs/toolkit";
import { treasuryApi } from "../../http";
import { TreasuryTransaction } from "./types/treasury-transaction.type";

export const getAccountBalancesActionsAction = createAsyncThunk(
    "treasury/getAccountBalances",
    async () => {
        return treasuryApi.getAccountBalances();
    }
)

export const getRwaContractBalancesAction = createAsyncThunk(
    "treasury/getRwaContractBalances",
    async () => {
        return treasuryApi.getRwaContractBalances();
    }
)

export const getTreasuryTransactionsMetaAction = createAsyncThunk(
    "treasury/getTreasuryTransactionsMeta",
    async () => {
        return treasuryApi.getTreasuryTransactionsMeta();
    }
)

export const getTreasuryTransactionsAction = createAsyncThunk(
    "treasury/getTreasuryTxs",
    async ({ id, page, pageSize }: { id: TreasuryTransaction['id'], page?: number, pageSize?: number }) => {
        return treasuryApi.getTreasuryTxs(id, { from: (page && pageSize) ? page * pageSize : 0, take: pageSize });

    }
)