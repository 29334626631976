import { OpenInNew } from "@mui/icons-material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CommodityTokenTx, Paginated } from "../../../types";
import { toShortHash } from "../../../utils/blockchain-utils";
import { formatNumberWithSeparator } from "../../../utils/number-utils";
import WidgetList from "../../dashboard/components/WidgetList";
import WidgetPaginator from "../../dashboard/components/WidgetPaginator";
import { getTreasuryTransactionsAction } from "../treasury.effects";
import {
  selectTreasuryTransactions,
  selectTreasuryTransactionsMeta,
} from "../treasury.selectors";
import { TreasuryTransaction } from "../types/treasury-transaction.type";
import classNames from "classnames";

interface IProps {
  id: TreasuryTransaction["id"];
}

const renderHeader = () => (
  <div className="flex justify-between items-center">
    <span className="flex-1">Time</span>
    <span className="flex-1">Type</span>
    <span className="flex-1">Asset</span>
    <span className="flex-1">Value</span>
    <span className="flex-1 hidden md:block">From</span>
    <span className="flex-[0_0_30px] text-center">TX</span>
  </div>
);

const renderItem = (tx: CommodityTokenTx) => {
  return (
    <div className="flex justify-between items-center text-sm">
      <span className="flex-1">
        {moment(tx.blockTimestamp).fromNow().capitalizeFirstLetter()}
      </span>
      <span className={classNames("flex-1", `text-${tx.action.toLowerCase()}`)}>
        {tx.action.toUpperCase()}
      </span>
      <span className={classNames("flex-1", `text-${tx.action.toLowerCase()}`)}>
        {formatNumberWithSeparator(tx.amount.toFixed(2))} {tx.rwaSymbol}
      </span>
      <span className={classNames("flex-1", `text-${tx.action.toLowerCase()}`)}>
        {formatNumberWithSeparator(tx.totalPrice.toFixed(2))} {tx.tokenSymbol}
      </span>
      <span className="flex-1 hidden md:block">{toShortHash(tx.account)}</span>
      <span className="flex-[0_0_30px] text-center">
        <a href={tx.txUrl} target="_blank">
          <OpenInNew className="text-base" />
        </a>
      </span>
    </div>
  );
};

export default function TreasuryInternalTransactions({ id }: IProps) {
  const transactionsMeta = useSelector(selectTreasuryTransactionsMeta);
  const paginatedTransactions = useSelector(selectTreasuryTransactions) as {
    id: TreasuryTransaction["id"];
    payload: Paginated<CommodityTokenTx>;
  };
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(7);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTreasuryTransactionsAction({ id, page, pageSize }));
  }, [transactionsMeta, id, page, pageSize]);

  return paginatedTransactions?.id == id ? (
    <div className="w-full h-full flex flex-col">
      <div className="flex-1 px-4 pt-2.5">
        <WidgetList
          items={paginatedTransactions.payload.data}
          getKey={(item) => item.txHash}
          renderHeader={renderHeader}
          renderItem={renderItem}
        />
      </div>
      <div className="WidgetFooter flex justify-end">
        <WidgetPaginator
          page={page}
          pageSize={pageSize}
          total={paginatedTransactions.payload.total || 0}
          onPageChange={setPage}
        />
      </div>
    </div>
  ) : null;
}
